<template>
    <div class="container">
        <div class="title perform_box">
                    <div class="title_txt flex_center_start_box">
                        <img src="../../../assets/img/detail/title_ic7.png" />
                        Supply Chain
                    </div>
                </div>
        <div class="right_cont_box" v-if="links.length>0">
            <div class="flex_center_between_box" >
                <div class="title_txt flex_center_start_box">
                    <img src="../../../assets/img/detail/title_ic7.png" />
                    Supply Chain
                </div>
                <div class="head_loging">
                    <el-switch v-model="checkEchartShow" @change="changeEchart" active-color="#13ce66"></el-switch>
                </div>
            </div>
            <div ref="SupplyChainEchart" v-show="checkEchartShow" style="width: 1140px; height: 510px"></div>
        </div>

        <div class="computer_right_cont_box" v-if="parentTablelist.length > 0">
            <div class="title" style="margin-top: 20px; margin-bottom: 0">
                <span class="title-left title_txt">
                    <img src="../../../assets/img/detail/title_ic7.png" style="margin-right: 8px" />
                    Related Supply Chain
                </span>
            </div>
            <div class="infinite-list-wrapper" v-infinite-scroll="infiniteScroll" style="overflow: auto" :infinite-scroll-disabled="false" :infinite-scroll-distance="5">
                <!-- <div v-infinite-scroll="load" infinite-scroll-disabled="disabled"> -->
                <div class="footer" v-for="(item, index) in parentTablelist" :key="index" v-loading="item.loading2">
                    <div class="ecahrts-header">
                        <span class="title-left title_txt">
                            <img src="../../../assets/img/detail/title_ic7.png" alt="" style="margin-right: 8px" />
                            <span v-show="!showTranslate"  style="color: #1290c9; cursor: pointer; margin-right: 5px; width: 580px" class="line_1" @click="setid(item)">{{ item.nameEn }}</span>
                            <span v-show="showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px;width: 580px;" class="line_1" @click="setid(item)">{{ item.nameCn }}</span>
                            
                            Supply Chain
                        </span>
                        <span style="display: flex;justify-content: flex-start; width: 280px;">relations:{{ item.typeEn }}</span>

                        <div class="head_loging">
                            <el-switch active-color="#13ce66" :value="loginStatus1[index]" @change="loginPop(index, item.aaaid, item.nameEn)"></el-switch>
                        </div>
                    </div>
                    <div v-show="loginStatus1[index]" class="ecahrtstu">
                        <div class="table">
                            <div class="table_box" style="position: relative">
                                <div class="echarts" ref="chartRef" style="width: 1140px; height: 510px"></div>
                                <!-- <div ref="SupplyChainEchart" v-show="checkEchartShow" style="width: 1140px; height: 510px"></div> -->
                                <!-- <button @click="update()" class="btn_blue">High quality information</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="back-to-top" v-show="showBackToTop" @click="scrollToTop">
            <button class="el-icon-top gaodu"></button>
        </div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <!-- <div class="dialog_detail_title">Pay attention to</div> -->
                <div class="detail_cont">
                    <div class="detail_cont">
                        <div class="detail_cont_text">• Import & export information is a paid feature, this will cost 2 units.</div>
                    </div>

                    <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                </div>
            </div>
            <div class="flex_center_between_box">
                <el-button class="btn_export" id="ok" @click="onSubmitkoudian()">OK</el-button>
                <el-button class="btn_export" @click="onSubmitquxiao()">Buy credits</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getSupplyChain, getrelatedCompanylist, getCompanyDetailMenuList, ordersAdd } from '@/api/companyApi.js';
import crypto from '@/utils/crypto';
import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    data() {
        return {
            checkEchartShow: false,
            obj: {},
            nodes: [], //{ name: 'Climate change' }
            links: [],
            linkpre: [],
            // { source: 'Total', target: 'Environment', value: 0.342284047256003 },
            loginStatus1: [],
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            parentTablelist: [],
            showBackToTop: false,
            newDialog:false,
            indexclose: '',
            Obj: {
                id: '',
                name: '',
                index: '',
            },
            params: {},
         
        };
    },
    computed: {
        showTranslate: {
            get() {
                return this.$parent.$parent.indexIsTranslate;
            },
            set() {},
        },
    },
    created() {
        this.getData();
        this.getrelateList();
    },
    mounted() {
        // window.addEventListener('resize', this.riskEchart)
   
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        // 移除页面滚动事件的监听
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        closeDialog() {
            const okButton = document.getElementById('ok');

            okButton.removeEventListener('click', this.onSubmitkoudian);
            this.loginStatus1[this.indexclose] = false;
            this.parentTablelist[this.indexclose].loading2 = false;
            this.$set(this.parentTablelist, this.indexclose, this.parentTablelist[this.indexclose]);
            this.newDialog = false;
        },
        // 提示扣点
        onSubmitkoudian() {
            let that = this;
            let id = this.Obj.id;
            let nameEn = this.Obj.name;
        this.getOrdersAdd(13, id, nameEn).then(res => {
                        if (res) {
                            let params = this.params;
                         
                            let index = that.Obj.index;
                            this.getparamslist(params, index)
                        } else {
                            let index = that.Obj.index;
                            this.loginStatus1[index] = false;
                            this.parentTablelist[index].loading2 = false;
                            this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                        }
                    });
            this.newDialog = false;
        },
        // 取消
        onSubmitquxiao() {
            // this.newDialog = false;
            // this.$emit('closeCloseDialog', false);
            let that = this;
            let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

            console.log(`userinfo`, userinfo);
            let routeData = '';
            setTimeout(function () {
                if (userinfo.type == '3') {
                    routeData = that.$router.resolve({
                        path: '/account/quota',
                    });
                } else {
                    routeData = that.$router.resolve({
                        path: '/account/addCredit',
                    });
                }

                window.open(routeData.href, '_blank');
            }, 1000);
        },
        getData() {
            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;
            const base = JSON.parse(sessionStorage.getItem('base'));
            let params = 'id3a=' + id3a + '&companyCountry=' + companyCountry + '&companyName=' ;
            getSupplyChain(params).then(result => {
                let data = JSON.parse(crypto.decrypt(result));
                if (data && data.code && data.data) {
                    console.log('result- SupplyChain', data);
                    const listNode = data.data.nodes;
                    let arr = [];
                    const listLinks = data.data.links;
                    let arrLink = [];

                    listNode.forEach(item => {
                        const pobj = { name: item.nameEn };
                        arr.push(pobj);
                    });
                    this.nodes = arr;

                    listLinks.forEach((item, index) => {
                        const obj = {
                            source: item.sourceEn,
                            target: item.targetEn,
                            value: item.value,
                        };
                        arrLink.push(obj);
                    });
                    // this.linkpre = arrLink
                    this.links = arrLink;
                    // this.findLink()
                }
            });
        },
        //source = target时，数据就是回流了
        // findLink() {
        //     //当前source 不在其他target中
        //     let linkArr = [];
        //     this.linkpre.forEach((link, index, arr) => {
        //         if (
        //             arr.every(i => {
        //                 return link.source != i.target;
        //             })
        //         ) {
        //           linkArr.push(link);
        //         }
        //     });
        //     this.links=linkArr
        // },

        //显示echarts
        changeEchart() {
            if (this.checkEchartShow) {
                this.suppleEchart();
            }
        },

        suppleEchart() {
            let that = this;
            this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);
            const option = {
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove',
                },
                series: [
                    {
                        type: 'sankey',
                        data: that.nodes,
                        links: that.links,
                        emphasis: {
                            focus: 'adjacency',
                        },
                        levels: [
                            {
                                depth: 0,
                                itemStyle: {
                                    color: '#fbb4ae',
                                },
                                lineStyle: {
                                    color: 'source',
                                    opacity: 0.6,
                                },
                            },
                            {
                                depth: 1,
                                itemStyle: {
                                    color: '#b3cde3',
                                },
                                lineStyle: {
                                    color: 'source',
                                    opacity: 0.6,
                                },
                            },
                            {
                                depth: 2,
                                itemStyle: {
                                    color: '#ccebc5',
                                },
                                lineStyle: {
                                    color: 'source',
                                    opacity: 0.6,
                                },
                            },
                            {
                                depth: 3,
                                itemStyle: {
                                    color: '#decbe4',
                                },
                                lineStyle: {
                                    color: 'source',
                                    opacity: 0.6,
                                },
                            },
                        ],
                        lineStyle: {
                            curveness: 0.5,
                        },
                    },
                ],
            };
            this.echartsBox.setOption(option);
        },
        infiniteScroll() {
            // console.log(`111`, 111);
            this.routeLoad = false;
            // this.page.pageNo += 1; // 页码每次滚动+1
            // this.getrelateList();
        },
        getrelateList() {
            getrelatedCompanylist({ id3a: this.$route.query.id3a, page: this.page.pageNo, pageSize: this.page.pageSize, resourceType: 2 }).then(res => {
                console.log(res);
                if (res) {
                    let list = res.data.rows;
                   res.data.rows==null? res.data.rows=[]:res.data.rows
                    this.parentTablelist = res.data.rows;
                    // for (let i = 0; i < list.length; i++) {
                    //     this.parentTablelist.push(list[i]);
                    // }
                    // // this.companyTblList = this.companyList;
                    // if (list.length < 10) {
                    //     this.noMore = true;
                    // }
                    // if (this.parentTablelist.length === res.data.total) {
                    //     this.noMore = true;
                    // }
                    this.routeLoad = false;
                    // this.parentTablelist = res.data.rows;
                    // this.parentTablelist[1].aaaid=156024802795
                    this.parentTablelist.map(item => {
                        item.loading2 = false;
                        item.listNode = [];
                        item.listLinks = [];
                        item.chartInstance = null;

                        // item.loading1=fal
                    });
                    console.log(`this.parentTablelist`, this.parentTablelist);
                }
            });
        },
        async loginPop(index, id, nameEn) {
            this.indexclose = index;
            this.$set(this.loginStatus1, index, !this.loginStatus1[index]);

            if (this.loginStatus1[index]) {
                this.parentTablelist[index].loading2 = true;
                let params = 'id3a=' + id + '&companyName=';

                let res = await getCompanyDetailMenuList('code=' + id + '&type=' + 7);
                let power = res.data[6].power;
                if (power) {
                    this.getparamslist(params, index);
                } else {
                    const okButton = document.getElementById('ok');
                    this.newDialog = true;
                    this.params = params;
               
                    this.Obj.name = nameEn;
                    this.Obj.id = id;
                    this.Obj.index = index;
            
                }
            }
        },
        async getOrdersAdd(type, aid, nameEn) {
            const id3a = aid;

            //1  :线上报告
            let params = {
                companyName: nameEn, //必须
                aaaId: id3a, //必须
                // companyCountry: companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            let status = '';

            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    //  getCompanyDetailMenuList('code=' + aid + '&type=' + 7).then(res=>{
                    //  console.log(res);
                    //  });
                    status = true;
                    if (data.msg != 'Saved successfully') {
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    }
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                        // duration: 5000,
                    });
                    let that = this;
                    let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

                    let routeData = '';
                    setTimeout(function () {
                        if (userinfo.type == '3') {
                            routeData = that.$router.resolve({
                                path: '/account/quota',
                            });
                        } else {
                            routeData = that.$router.resolve({
                                path: '/account/addCredit',
                            });
                        }

                        window.open(routeData.href, '_blank');
                    }, 2000);

                    status = false;
                }
            });
            this.getliulantime(params,'SupplyChain')
            return status;
        },
        getparamslist(params, index) {
            getSupplyChain(params).then(res => {
                let data = JSON.parse(crypto.decrypt(res));

                if (data.code === true) {
                    this.parentTablelist[index].loading2 = false;
                    const listNode = data.data.nodes;
                    let arr = [];
                    const listLinks = data.data.links;
                    let arrLink = [];

                    listNode.forEach(item => {
                        const pobj = { name: item.nameEn };
                        arr.push(pobj);
                    });
                    this.parentTablelist[index].nodes = arr;

                    listLinks.forEach((item, index) => {
                        const obj = {
                            source: item.sourceEn,
                            target: item.targetEn,
                            value: item.value,
                        };
                        arrLink.push(obj);
                    });
                    // this.linkpre = arrLink
                    this.parentTablelist[index].links = arrLink;

                    let that = this;
                    that.loginStatus1[index] = true;
                    const option = {
                        tooltip: {
                            trigger: 'item',
                            triggerOn: 'mousemove',
                        },
                        series: [
                            {
                                type: 'sankey',
                                data: that.parentTablelist[index].nodes,
                                links: that.parentTablelist[index].links,
                                emphasis: {
                                    focus: 'adjacency',
                                },
                                levels: [
                                    {
                                        depth: 0,
                                        itemStyle: {
                                            color: '#fbb4ae',
                                        },
                                        lineStyle: {
                                            color: 'source',
                                            opacity: 0.6,
                                        },
                                    },
                                    {
                                        depth: 1,
                                        itemStyle: {
                                            color: '#b3cde3',
                                        },
                                        lineStyle: {
                                            color: 'source',
                                            opacity: 0.6,
                                        },
                                    },
                                    {
                                        depth: 2,
                                        itemStyle: {
                                            color: '#ccebc5',
                                        },
                                        lineStyle: {
                                            color: 'source',
                                            opacity: 0.6,
                                        },
                                    },
                                    {
                                        depth: 3,
                                        itemStyle: {
                                            color: '#decbe4',
                                        },
                                        lineStyle: {
                                            color: 'source',
                                            opacity: 0.6,
                                        },
                                    },
                                ],
                                lineStyle: {
                                    curveness: 0.5,
                                },
                            },
                        ],
                    };
                    const chartDom = this.$refs.chartRef[index];
                    // console.log(this.$refs.chartRef[index]);
                    const chart = this.$echarts.getInstanceByDom(chartDom) || this.$echarts.init(chartDom);
                    chart.setOption(option);
                    this.parentTablelist[index].chartInstance = chart;
                    this.$set(this.parentTablelist, index, this.parentTablelist[index]);
 
                } else {
                    // Message({
        
                }
            });
        },
        setid(item) {
            // sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + item.aaaid + '&companyCountry=' + '',
            });

            window.open(routeData.href, '_blank');
        },
        handleScroll() {
            // 判断页面滚动距离是否超过200px，更新showBackToTop的值
            // console.log(` this.showBackToTop`, this.showBackToTop );
            this.showBackToTop = window.pageYOffset > 1000;
        },
        scrollToTop() {
            // 使用scrollTo()方法将页面滚动到顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>
<style scoped lang="less">
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.perform_box {
    margin-top: 60px;
}
.title {
    margin-bottom: 30px;
}
.right_cont_box {
    margin-top: 20px;
    padding: 20px;
}
.right_cont_box .title {
    margin-bottom: 20px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.i_e_group {
    display: flex;
    justify-content: space-between;
}
.i_e_item {
    width: 230px;
    padding: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 8px;
}
.i_e_item .title {
    font-size: 14px;
    color: #022955;
    font-family: 'Arial Bold';
    margin-bottom: 6px;
}
.i_e_item .tip {
    font-size: 12px;
    color: #8497ab;
    margin-bottom: 14px;
}
.i_e_item .line_step,
.i_e_item .line {
    font-size: 12px;
    color: #022955;
    margin-bottom: 24px;
}
.i_e_item .line_step {
    margin-bottom: 14px;
}
.i_e_item .line .step {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    margin-right: 6px;
    background: #1290c9;
    border-radius: 50%;
}
.i_e_item .line_step .line_title {
    display: inline-block;
    width: 154px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.gree_num {
    color: #55b419;
    text-align: right;
}
.blue_num {
    text-align: right;
    color: #1290c9;
}
.i_e_item .line_step /deep/ .el-progress {
    margin-top: 4px;
}
.switch /deep/ .el-switch__core {
    width: 60px !important;
    height: 30px;
    border-radius: 15px;
}
.switch /deep/ .el-switch__core:after {
    width: 26px;
    height: 26px;
}
.switch /deep/ .el-switch.is-checked .el-switch__core::after {
    left: 100%;
    margin-left: -26px !important;
}

.title {
    display: flex;
    align-items: center;

    justify-content: space-between;
    // margin-top: 69px;

    .title-left {
        display: flex;
        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #022955;
    }
}

.table {
    margin-top: 20px;
}
.footer {
    margin-top: 20px;
    padding: 20px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.ecahrts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
        display: flex;
    }
}
.infinite-list-wrapper {
    padding: 4px;
}
.back-to-top {
    z-index: 999;
    position: fixed;
    transition: opacity 0.4s ease-in-out 0s;
    opacity: 1;
    box-sizing: border-box;
    bottom: 86px;
    right: 45px;
    cursor: pointer;
    /* background-color: #1290c9; */
}

.gaodu {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #1290c9;
    color: #fff;
    font-size: 20px;
}
.line_1 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.detail_cont {
    padding: 0px 10px 10px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}
</style>
